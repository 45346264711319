<template>
  <div>
    <Pos :content="$route.params.name" />
    <el-tabs :stretch="true" v-model="tabsName" @tab-click="__loadFollowList">
      <el-tab-pane label="Followers" name="followers">
        <ul class="follow-list">
          <li
            v-for="{
              follow_id,
              is_follow,
              user: {
                user_name,
                user_email,
                user_id,
                user_avatar,
                user_content,
              },
            } in followersDataList"
            :key="follow_id"
          >
            <div class="header-box">
              <img
                @click="(e) => goAccount(e, user_name, user_id)"
                class="avatar"
                :src="
                  user_avatar
                    ? user_avatar
                    : 'https://pbs.twimg.com/profile_images/446356636710363136/OYIaJ1KK_normal.png'
                "
              />
              <div>
                <p class="account">{{ user_name }}</p>
                <p class="email">{{ user_email }}</p>
              </div>

              <p
                style="margin-left: auto"
                v-if="user_id !== $store.state.userStore.userInfo.user_id"
              >
                <FollowBtn :is-follow="is_follow" :target-id="user_id" />
              </p>
            </div>
            <p class="content">{{ user_content }}</p>
          </li>
        </ul>
      </el-tab-pane>
      <el-tab-pane label="Following" name="following">
        <ul class="follow-list">
          <li
            v-for="{
              follow_id,
              is_follow,
              user: {
                user_name,
                user_email,
                user_id,
                user_avatar,
                user_content,
              },
            } in followingDataList"
            :key="follow_id"
          >
            <div class="header-box">
              <img
                @click="(e) => goAccount(e, user_name, user_id)"
                class="avatar"
                :src="
                  user_avatar
                    ? user_avatar
                    : 'https://pbs.twimg.com/profile_images/446356636710363136/OYIaJ1KK_normal.png'
                "
              />
              <div>
                <p class="account">{{ user_name }}</p>
                <p class="email">{{ user_email }}</p>
              </div>

              <p
                style="margin-left: auto"
                v-if="user_id !== $store.state.userStore.userInfo.user_id"
              >
                <FollowBtn :is-follow="is_follow" :target-id="user_id" />
              </p>
            </div>
            <p class="content">{{ user_content }}</p>
          </li>
        </ul>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Pos from "@/components/Pos";
import FollowBtn from "@/components/FollowBtn";
import account from "@/mixins/account.js";
import { followList } from "@/api/follow";
export default {
  name: "follow",
  data() {
    return {
      tabsName: "",
      //  正在关注
      followingDataList: [],
      // 被关注
      followersDataList: [],
    };
  },
  components: {
    Pos,
    FollowBtn,
  },
  mixins: [account],
  created() {
    this.tabsName = this.$route.params.type;
    this.__loadFollowList();
  },
  methods: {
    async __loadFollowList() {
      const { id: target_user_id } = this.$route.params;
      const { data, code, msg } = await followList({
        page: 1,
        limit: 9999,
        target_type: this.tabsName,
        target_user_id,
        follow_type: 2,
      });
      if (code === 200) {
        this[`${this.tabsName}DataList`] = data;
      } else {
        this.$message.error(msg);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.follow-list {
  li {
    padding: 12px 16px;

    div.header-box {
      display: flex;
      align-items: center;

      .avatar {
        cursor: pointer;
        display: block;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin-right: 12px;
      }

      div {
        p {
          font-size: 15px;
          line-height: 20px;

          &.account {
            font-weight: 700;
            color: rgb(15, 20, 25);
          }

          &.email {
            color: rgb(83, 100, 113);
            font-weight: 400;
          }
        }
      }
    }

    p.content {
      margin-top: 4px;
      margin-left: 60px;
      font-size: 15px;
      font-weight: 400;
      color: rgb(15, 20, 25);
    }
  }
}
</style>