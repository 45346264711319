import { get, post } from '@/api/request';
// 关注列表
export const followList = (params) => get("followList", params, undefined, { showGlobalMessage: false });
// 检测是否关注该用户
export const checkFollow = (params) => post("checkFollow", params, undefined, { showGlobalMessage: false });
// 关注
export const follow = (params = { target_id: 1, follow_type: 1 }) => post("follow", params, undefined, { showGlobalMessage: false, showLoading: false });
// 取消关注
export const unFollow = (params = { target_id: 1, follow_type: 1 }) => post("unFollow", params, undefined, { showGlobalMessage: false, showLoading: false });


