var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Pos',{attrs:{"content":_vm.$route.params.name}}),_c('el-tabs',{attrs:{"stretch":true},on:{"tab-click":_vm.__loadFollowList},model:{value:(_vm.tabsName),callback:function ($$v) {_vm.tabsName=$$v},expression:"tabsName"}},[_c('el-tab-pane',{attrs:{"label":"Followers","name":"followers"}},[_c('ul',{staticClass:"follow-list"},_vm._l((_vm.followersDataList),function(ref){
          var follow_id = ref.follow_id;
          var is_follow = ref.is_follow;
          var ref_user = ref.user;
          var user_name = ref_user.user_name;
          var user_email = ref_user.user_email;
          var user_id = ref_user.user_id;
          var user_avatar = ref_user.user_avatar;
          var user_content = ref_user.user_content;
return _c('li',{key:follow_id},[_c('div',{staticClass:"header-box"},[_c('img',{staticClass:"avatar",attrs:{"src":user_avatar
                  ? user_avatar
                  : 'https://pbs.twimg.com/profile_images/446356636710363136/OYIaJ1KK_normal.png'},on:{"click":function (e) { return _vm.goAccount(e, user_name, user_id); }}}),_c('div',[_c('p',{staticClass:"account"},[_vm._v(_vm._s(user_name))]),_c('p',{staticClass:"email"},[_vm._v(_vm._s(user_email))])]),(user_id !== _vm.$store.state.userStore.userInfo.user_id)?_c('p',{staticStyle:{"margin-left":"auto"}},[_c('FollowBtn',{attrs:{"is-follow":is_follow,"target-id":user_id}})],1):_vm._e()]),_c('p',{staticClass:"content"},[_vm._v(_vm._s(user_content))])])}),0)]),_c('el-tab-pane',{attrs:{"label":"Following","name":"following"}},[_c('ul',{staticClass:"follow-list"},_vm._l((_vm.followingDataList),function(ref){
          var follow_id = ref.follow_id;
          var is_follow = ref.is_follow;
          var ref_user = ref.user;
          var user_name = ref_user.user_name;
          var user_email = ref_user.user_email;
          var user_id = ref_user.user_id;
          var user_avatar = ref_user.user_avatar;
          var user_content = ref_user.user_content;
return _c('li',{key:follow_id},[_c('div',{staticClass:"header-box"},[_c('img',{staticClass:"avatar",attrs:{"src":user_avatar
                  ? user_avatar
                  : 'https://pbs.twimg.com/profile_images/446356636710363136/OYIaJ1KK_normal.png'},on:{"click":function (e) { return _vm.goAccount(e, user_name, user_id); }}}),_c('div',[_c('p',{staticClass:"account"},[_vm._v(_vm._s(user_name))]),_c('p',{staticClass:"email"},[_vm._v(_vm._s(user_email))])]),(user_id !== _vm.$store.state.userStore.userInfo.user_id)?_c('p',{staticStyle:{"margin-left":"auto"}},[_c('FollowBtn',{attrs:{"is-follow":is_follow,"target-id":user_id}})],1):_vm._e()]),_c('p',{staticClass:"content"},[_vm._v(_vm._s(user_content))])])}),0)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }