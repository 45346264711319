<template>
  <div class="pos">
    <el-page-header @back="goBack" :title="title">
      <template #content>
        <strong>{{ content }}</strong>
      </template>
    </el-page-header>
  </div>
</template>

<script>
export default {
  name: "pos",
  data() {
    return {};
  },
  methods: {
    goBack() {
      this.back ? this.back() : this.$router.go(-1);
    },
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    back: {
      type: Function,
    },
  },
};
</script>

<style lang="scss" scoped>
.pos {
  width: 100%;
  padding: 18px 24px;
  box-sizing: border-box;
  //   height: 53px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: flex-start;
}
</style>