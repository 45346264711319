<template>
  <a
    href="javascript:;"
    :class="{ follow: isFollowing, unfollow: !isFollowing }"
    @click="autoFollow"
    @mouseenter="isHover = true"
    @mouseleave="isHover = false"
  >
    {{ content() }}
  </a>
</template>

<script>
import { checkFollow, follow, unFollow } from "@/api/follow";
export default {
  data() {
    return {
      isFollowing: false,
      isHover: false,
    };
  },
  props: {
    isFollow: {
      type: Number,
    },
    targetId: {
      type: Number,
      required: true,
    },
    // 2=用户 1=tag
    followType: {
      type: Number,
      default: 2,
    },
  },
  created() {
    this.__checkFollow();
  },
  methods: {
    // 检测关注状态
    async __checkFollow() {
      if (isNaN(this.isFollow)) {
        const { data } = await checkFollow({
          target_id: this.$route.params.id,
          follow_type: this.followType,
        });
        this.isFollowing = data;
      } else {
        this.isFollowing = !!this.isFollow;
        console.log(!!this.isFollow);
      }
    },
    async autoFollow() {
      const requestData = {
        target_id: this.targetId,
        follow_type: this.followType,
      };
      let responseData;

      if (this.isFollowing) {
        responseData = await unFollow(requestData);
      } else {
        responseData = await follow(requestData);
      }

      console.log(responseData);
      if (responseData.code === 200) {
        this.$message.success("操作成功");
        this.isFollowing = !this.isFollowing;
      } else {
        this.$message.error(responseData.msg);
      }
    },
    // 文本内容
    content() {
      if (this.isHover) {
        return this.isFollowing ? "UnFollow" : "Follow";
      } else {
        return this.isFollowing ? "Following" : "Follow";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  display: block;
  width: 102px;
  line-height: 36px;
  border-radius: 999px;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
  transition: background 300ms ease-in-out;
  &.follow {
    color: rgb(15, 20, 25);
    border: 1px solid #cfd9de;

    &:hover {
      background-color: #fcebeb;
      color: Red;
    }
  }

  &.unfollow {
    color: #cfd9de;
    background-color: rgb(15, 20, 25);
    border: 1px solid rgb(15, 20, 25);
  }
}
</style>